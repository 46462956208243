import cx from 'classnames';

import ArrowLeftIcon from 'assets/icons/arrow-right.svg';
import { HOME_META_DESCRIPTION } from 'consts/seo';
import classes from 'styles/Home.module.css';
import Container from 'ui/components/Container';
import Layout from 'ui/components/Layout';
import Meta from 'ui/components/Meta';
import Typography from 'ui/components/Typography';

export default function Home() {
  const mobileImageRendered = (
    <img
      className={classes.amforaMobileApp}
      alt="Amfora mobile application"
      src="/images/phone.png"
    />
  );

  return (
    <>
      <Meta description={HOME_META_DESCRIPTION} image="/images/phone.png" />
      <Layout>
        <Container className={classes.layoutContainer}>
          <main className={classes.root}>
            <img className={classes.logo} src="/brand/logo.svg" alt="Brand logotype" />

            <div className={classes.mainContainer}>
              <div className={classes.leftContainer}>
                <div className={classes.textContainer}>
                  <Typography variant="h1" as="h1" fontWeight={700}>
                    Top-rated wines from
                    <br />
                    the best vineyards
                  </Typography>

                  <Typography as="p">
                    Amfora is a curated wine shop, dedicated to top-rated wines only. Working
                    directly with carefully selected wineries, we specialise in popular styles from
                    the best vineyards and regions. From classic brands to hard-to-find artisanal
                    gems, our specialty selection offers the highest ranked, best quality wines at
                    every price point.
                  </Typography>
                </div>

                <div className={classes.outerCtaContainer}>
                  <div className={classes.ctaContainer}>
                    <div className={classes.ctaLinks}>
                      <a href="https://amfora.store" className={cx(classes.ctaLink, classes.link)}>
                        <Typography>Amfora Wine Store</Typography>
                        <ArrowLeftIcon />
                      </a>
                    </div>
                  </div>

                  <div className={classes.ctaMobileContainer}>{mobileImageRendered}</div>
                </div>
              </div>
              <div className={classes.mobileContainer}>{mobileImageRendered}</div>
            </div>
          </main>

          <footer>
            <a className={cx(classes.contactLink, classes.link)} href="mailto:hello@amfora.com">
              <div className={classes.emailIcon}></div>

              <Typography>
                {/* The a after the @ is tight. */}
                hello<span style={{ letterSpacing: 1 }}>@</span>amfora.com
              </Typography>
            </a>
          </footer>
        </Container>
      </Layout>
    </>
  );
}
