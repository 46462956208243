import React from 'react';
import Head from 'next/head';

interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
  noIndex?: boolean;
}

const Meta = (props: MetaProps) => {
  const { title: passedTitle, description, image, noIndex = false } = props;
  const baseName = 'Amfora';

  const title = [baseName, passedTitle].filter(Boolean).join(' – ');

  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="og:title" property="og:title" content={title} />

      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </>
      )}

      {image && <meta property="og:image" content={image} />}
      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  );
};

export default Meta;
