import React from 'react';
import cx from 'classnames';

import { PolymorphicComponent } from 'types/component';
import classes from './Container.module.css';

interface ContainerProps {
  children: React.ReactNode;
  center?: boolean;
  className?: string;
}

function Container<T extends React.ElementType>(props: PolymorphicComponent<T, ContainerProps>) {
  const { children, as, center = false, className, ...restProps } = props;

  const Component = as ?? 'div';

  return (
    <Component {...restProps} className={cx(classes.root, center && classes.center, className)}>
      {children}
    </Component>
  );
}

export default Container;
