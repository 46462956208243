import React from 'react';

import classes from './Layout.module.css';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { children } = props;

  return <div className={classes.root}>{children}</div>;
};

export default Layout;
