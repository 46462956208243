import React from 'react';
import cx from 'classnames';

import { PolymorphicComponent } from 'types/component';
import classes from './Typography.module.css';

export type TypographyFontWeightNumber = 400 | 700;
export type TypographyFontWeightString = 'normal' | 'bold';

const FONT_WEIGHT_MAP: Record<TypographyFontWeightString, TypographyFontWeightNumber> = {
  normal: 400,
  bold: 700,
};

interface TypographyProps {
  children: React.ReactNode;
  fontWeight?: TypographyFontWeightNumber | TypographyFontWeightString;
  variant?: 'regular' | 'h1';
  block?: boolean;
}

function Typography<T extends React.ElementType>(props: PolymorphicComponent<T, TypographyProps>) {
  const {
    children,
    fontWeight: rawFontWeight = 400,
    as,
    variant = 'regular',
    block = false,
    ...restProps
  } = props;

  const fontWeight =
    typeof rawFontWeight === 'string' ? FONT_WEIGHT_MAP[rawFontWeight] : rawFontWeight;
  const Component = as ?? 'span';

  return (
    <Component
      {...restProps}
      className={cx(
        classes.root,
        classes[`fontWeight-${fontWeight}`],
        classes[`variant-${variant}`],
        block && classes.block,
      )}
    >
      {children}
    </Component>
  );
}

export default Typography;
